<template>
  <div class="p-pegas">
    <div
      class="card-table"
      :class="mode ? 'card__tableday' : 'card__tablenight'"
    >
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div
                :class="mode ? 'content__titleday' : 'content__titlenight'"
                class="content-title d-flex align-center mr-2"
              >
                {{ $t("message.bonuses") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    :icon="'el-icon-search'"
                  ></crm-input>
                </div>
              </div>
              <div class="block-sarche ml-2">
                <div class="header__search">
                  <select-branch
                    size="small"
                    :class="mode ? 'filter__day' : 'filter__night'"
                    v-model="filterForm.branch_id"
                  >
                  </select-branch>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12" class="flex-style text-right">
            <crm-create-and-column-settings
              :permission="$options.name"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              @c-create="drawerCreate = true"
              :columns="columns"
              @c-change="updateColumn"
              :v_can_create="'bonuses.create'"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->
      <div class="card-table-header table-crm-smart">
        <table
          class="table-my-code table-bordered"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.company_id.show">
                {{ columns.company_id.title }}
              </th>

              <th v-if="columns.branch_id.show">
                {{ columns.branch_id.title }}
              </th>

              <th v-if="columns.department_id.show">
                {{ columns.department_id.title }}
              </th>

              <th v-if="columns.graphic_id.show">
                {{ columns.graphic_id.title }}
              </th>

              <th v-if="columns.staff_id.show">
                {{ columns.staff_id.title }}
              </th>
              <!-- <th v-if="columns.name.show">
                                {{ columns.name.title }}
                            </th> -->

              <th v-if="columns.amount.show">
                {{ columns.amount.title }}
              </th>

              <th v-if="columns.reason_id.show">
                {{ columns.reason_id.title }}
              </th>

              <th v-if="columns.comment.show">
                {{ columns.comment.title }}
              </th>

              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>

              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>

              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.company_id.show">
                <select-company
                  size="mini"
                  :placeholder="columns.company_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.company_id"
                >
                </select-company>
              </th>
              <th v-if="columns.branch_id.show">
                <select-branch
                  size="mini"
                  :placeholder="columns.branch_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.branch_id"
                >
                </select-branch>
              </th>
              <th v-if="columns.department_id.show">
                <select-department
                  size="mini"
                  :placeholder="columns.department_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.department_id"
                >
                </select-department>
              </th>
              <th v-if="columns.graphic_id.show">
                <select-graphic
                  size="mini"
                  :placeholder="columns.graphic_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.graphic_id"
                >
                </select-graphic>
              </th>
              <th v-if="columns.staff_id.show">
                <select-staff
                  size="mini"
                  :placeholder="columns.staff_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.staff_id"
                >
                </select-staff>
              </th>
              <!-- <th v-if="columns.name.show">
                                <crm-input
                                    size="mini"
                                    :inputValue="filterForm.name"
                                    :placeholder="columns.name.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.name"
                                ></crm-input>
                            </th> -->
              <th v-if="columns.amount.show">
                <crm-input
                  size="mini"
                  placeholder="Сумма бонуса"
                  v-model="filterForm.amount"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :type="'number'"
                ></crm-input>
              </th>
              <th v-if="columns.reason_id.show">
                <select-penatly-reason
                  size="mini"
                  :type="'bonuses'"
                  :placeholder="columns.reason_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.reason_id"
                ></select-penatly-reason>
                <!-- <select-reason
                                    size="mini"
                                    :placeholder="columns.reason_id.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.reason_id"
                                >
                                </select-reason> -->
              </th>
              <th v-if="columns.comment.show">
                <crm-input
                  size="mini"
                  placeholder="Комментарий"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.comment"
                ></crm-input>
              </th>
              <th v-if="columns.created_at.show">
                <crm-date-picker
                  size="mini"
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  size="mini"
                  :placeholder="columns.updated_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.updated_at"
                ></crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="bonus in list" :key="bonus.id" class="cursor-pointer">
              <td v-if="columns.id.show">
                {{ bonus.id }}
              </td>
              <td v-if="columns.company_id.show">
                {{ bonus.company ? bonus.company.name : "" }}
              </td>

              <td v-if="columns.branch_id.show">
                {{ bonus.branch ? bonus.branch.name : "" }}
              </td>

              <td v-if="columns.department_id.show">
                {{ bonus.department ? bonus.department.name : "" }}
              </td>

              <td v-if="columns.graphic_id.show">
                {{ bonus.graphic ? bonus.graphic.name : "" }}
              </td>

              <td v-if="columns.staff_id.show">
                {{
                  bonus.staff
                    ? bonus.staff.name + " " + bonus.staff.last_name
                    : ""
                }}
              </td>
              <!-- <td v-if="columns.name.show">
                                {{ bonus.name }}
                            </td> -->

              <td v-if="columns.amount.show">
                {{ bonus.amount ? bonus.amount.toLocaleString("fi-FI") : 0 }}
              </td>

              <td v-if="columns.reason_id.show">
                {{ bonus.reason ? bonus.reason.name : "" }}
              </td>

              <td v-if="columns.comment.show">
                {{ bonus.comment }}
              </td>

              <td v-if="columns.created_at.show">
                {{ bonus.created_at }}
              </td>

              <td v-if="columns.updated_at.show">
                {{ bonus.updated_at }}
              </td>

              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="bonus"
                  :permissionShow="'bonuses.update'"
                  :permissionDestroy="'bonuses.destroy'"
                  :actions="actions"
                  @edit="edit"
                  @delete="destroy"
                ></crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>

      <div class="app-modal app-modal__full modal-color-bg">
        <el-drawer
          :with-header="false"
          :visible.sync="drawerCreate"
          size="70%"
          ref="drawerCreate"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
        >
          <crm-create
            ref="drawerCreateChild"
            drawer="drawerCreate"
          ></crm-create>
        </el-drawer>

        <el-drawer
          :with-header="false"
          :visible.sync="drawerUpdate"
          size="70%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
import selectStaff from "@/components/filters/inventory/select-staff";
import selectCompany from "@/components/filters/inventory/select-company";
import selectBranch from "@/components/filters/inventory/select-branch";
import selectDepartment from "@/components/filters/inventory/select-department";
import selectPenatlyReason from "@/components/filters/inventory/select-penatly-reason";
import selectGraphic from "@/components/filters/inventory/select-graphic";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import list from "@/utils/mixins/list";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "BonusController",
  mixins: [list],
  components: {
    CrmCreate,
    CrmUpdate,
    selectStaff,
    selectCompany,
    selectBranch,
    selectDepartment,
    selectPenatlyReason,
    selectGraphic
  },
  computed: {
    ...mapGetters({
      list: "bonus/list",
      columns: "bonus/columns",
      pagination: "bonus/pagination",
      statues: "bonus/statues",
      filter: "bonus/filter",
      sort: "bonus/sort",
      mode: "MODE"
    }),
    actions: function() {
      return ["edit", "delete"];
    }
  },
  methods: {
    ...mapActions({
      updateList: "bonus/index",
      setPagination: "bonus/setPagination",
      updateSort: "bonus/updateSort",
      updateFilter: "bonus/updateFilter",
      updateColumn: "bonus/updateColumn",
      updatePagination: "bonus/updatePagination",
      show: "bonus/show",
      empty: "bonus/empty",
      delete: "bonus/destroy",
      refreshData: "bonus/refreshData"
    }),
    destroy(model) {
      this.delete(model)
        .then(res => {
          this.$alert(res);
          this.fetchData();
        })
        .catch(err => {
          this.$alert(err);
        });
    }
  }
};
</script>

<style lang="scss">
tr td::nth-last-child(2) {
  background: red;
}
.card-table-header {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .el-input__inner[type="number"] {
    padding-right: 15px !important;
  }
}
</style>
